// VerticalSstela.jsx
import React, { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader, RepeatWrapping, sRGBEncoding } from 'three';
import materials from './materials';

const VerticalSstela = ({ rp_1MaterialIndex, monumentIndex, position, stelaRotation }) => {
  const { scene } = useThree();
  const modelRef = useRef();
  const textureLoader = new TextureLoader();

  useEffect(() => {
    const loader = new GLTFLoader();
    
    // Загружаем модель стелы
    loader.load(`/mesh/rp_${monumentIndex}.glb`, (gltf) => {
      if (modelRef.current) {
        scene.remove(modelRef.current); // Убираем предыдущую стелу перед добавлением новой
      }
      modelRef.current = gltf.scene;
      scene.add(gltf.scene);

      modelRef.current.position.set(...position);
      modelRef.current.rotation.y = stelaRotation; // Применяем поворот по оси Y
      updateTextures(rp_1MaterialIndex);
    });

    // Удаляем стелу при размонтировании компонента или обновлении
    return () => {
      if (modelRef.current) {
        scene.remove(modelRef.current); // Удаляем стелу из сцены при изменении состояния
      }
    };
  }, [scene, rp_1MaterialIndex, monumentIndex, position, stelaRotation]);

  const updateTextures = (materialIndex) => {
    if (!modelRef.current) return;
    const materialConfig = materials[`rp_${monumentIndex}`][materialIndex];
    if (!materialConfig) return;

    const textureScale = materialConfig.scale || 1;

    const onLoadTexture = (texture, flipY = false) => {
      texture.encoding = sRGBEncoding;
      texture.wrapS = texture.wrapT = RepeatWrapping;
      texture.repeat.set(textureScale, textureScale);
      texture.flipY = flipY;
      texture.needsUpdate = true;
    };

    const baseColorMap = textureLoader.load(materialConfig.baseColor, (texture) => onLoadTexture(texture));
    const roughnessMap = textureLoader.load(materialConfig.roughness, (texture) => onLoadTexture(texture));
    const normalMap = textureLoader.load(materialConfig.normalMap, (texture) => onLoadTexture(texture));

    modelRef.current.traverse((child) => {
      if (child.isMesh) {
        child.material.map = baseColorMap;
        child.material.roughnessMap = roughnessMap;
        child.material.normalMap = normalMap;
        child.material.metalness = materialConfig.metalness || 0.5;
        child.material.needsUpdate = true;
      }
    });
  };

  return null;
};

export default VerticalSstela;
