import React from 'react';
import TextureThumbnail from './TextureThumbnail'; // Импортируем компонент для отображения текстур
import materials from './materials'; // Импорт материалов

const TextureList = ({ materialSet, onTextureClick }) => {
  const textures = materials[materialSet];

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {textures.map((texture, index) => (
        <TextureThumbnail
          key={index}
          texture={texture.baseColor}
          onClick={() => onTextureClick(index)}
        />
      ))}
    </div>
  );
};

export default TextureList;