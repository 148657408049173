// TextureThumbnail.jsx
import React from 'react';

const TextureThumbnail = ({ texture, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: '50px',
        height: '50px',
        backgroundImage: `url(${texture})`,
        backgroundSize: 'cover',
        border: '2px solid transparent',
        cursor: 'pointer',
        margin: '5px',
        borderRadius: '50%', // Округление до круга
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
      }}
    />
  );
};

export default TextureThumbnail;