// ColorIndicator.jsx
import React from 'react';
import materials from './materials'; // Убедитесь, что путь к файлу materials.js указан правильно

const ColorIndicator = ({ materialIndex, type }) => {
  const materialConfigs = materials[type];

  if (!materialConfigs || materialConfigs.length === 0) {
    return null;
  }

  const materialConfig = materialConfigs[materialIndex];

  if (!materialConfig) {
    return null;
  }

  return (
    <img
      src={materialConfig.baseColor}
      alt="Texture preview"
      style={{
        width: '100%',
        height: '30px',
        objectFit: 'cover',
        display: 'inline-block',
      }}
    />
  );
};

export default ColorIndicator;