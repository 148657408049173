import React, { useState, useEffect } from 'react';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls, RoundedBox, Text, Box, Plane } from '@react-three/drei';
import Background from './Background';
import materials from './materials'; // Импорт материалов
import ColorIndicator from './ColorIndicator'; // Импорт компонента для отображения текстур
import TextureList from './TextureList'; // Импорт нового компонента
import * as THREE from 'three';
import VerticalSstela from './VerticalSstela';
import Polka from './Polka';
import ColorPikerTextureList from './ColorPikerTextureList';
import ThumbnailSelect from './ThumbnailSelect';
import { useDrag } from 'react-use-gesture';
import { TextureLoader } from 'three'; // Импорт TextureLoader


const goBack = () => {
  window.history.back();
};



const Pedestal = ({ stelaPosition, index, pedestalTexturesArray, sizePedestal }) => {
  const zOffset = 0.05;

  const updatedPosition = [
    stelaPosition[0],
    stelaPosition[1] - 0.07,
    stelaPosition[2] + zOffset,
  ];

  return (
    <>
    <RoundedBox
      position={[updatedPosition[0], updatedPosition[1]+ 0.077, updatedPosition[2] ]}  // Приподнято на 1 по оси Z
      args={[sizePedestal, 0.001, 0.2]}  // Используем переданный размер
      radius={0.001}
      smoothness={1}
    >
      <meshStandardMaterial
        map={pedestalTexturesArray.map} // Используем текстуру
        roughnessMap={pedestalTexturesArray.roughnessMap}
        normalMap={pedestalTexturesArray.normalMap}
        roughness={0.25}
        metalness={0.5}
      />
    </RoundedBox>
    <RoundedBox
    position={updatedPosition}
    args={[sizePedestal, 0.15, 0.2]}  // Используем переданный размер
    radius={0.003}
    smoothness={4}
  >
    <meshStandardMaterial color="gray" />
  </RoundedBox>
  </>
  );
};

const Land = ({ stelaPosition, index, landTexturesArray, zvetnikTexturesArray, sizeLand }) => {
  const zOffset = 0.652;

  const updatedPosition = [
    stelaPosition[0],
    stelaPosition[1] - 0.14,
    stelaPosition[2] + zOffset,
  ];

  return (
    <>
    {/* Главный RoundedBox для земли */}
    <RoundedBox
      position={updatedPosition}
      args={[sizeLand, 0.005, 1]}  // Основной размер земли
      radius={0}
      smoothness={4}
    >
      <meshStandardMaterial  //текстуры цветника и земли перепутаны местами
        map={zvetnikTexturesArray.map}
        roughnessMap={zvetnikTexturesArray.roughnessMap}
        normalMap={zvetnikTexturesArray.normalMap}
        roughness={0.8}
        metalness={0}
      />
    </RoundedBox>

    {/* ближнего края оси Y*/}
    <RoundedBox
      position={[updatedPosition[0] , updatedPosition[1] + 0.033 , updatedPosition[2] + 0.466]}  // Позиция вдоль оси Y (дальний край)
      args={[sizeLand + 0.001, 0.07, 0.07]}  // Размеры для ближнего края оси Y
      radius={0}
      smoothness={4}
    >
      <meshStandardMaterial color="gray" />
    </RoundedBox>

      {/* Верхняя грань*/}
      <RoundedBox 
      position={[updatedPosition[0], updatedPosition[1] + 0.14 / 2, updatedPosition[2] + 0.466]}  // Позиция над RoundedBox
      rotation={[-Math.PI / 2, 0, 0]}  // Поворот, чтобы плоскость легла горизонтально
      args={[sizeLand + 0.001, 0.07, 0.001]} 
      radius={0.002}
      smoothness={4}
      >  {/* Размеры, соответствующие RoundedBox */}
      <meshStandardMaterial
        map={landTexturesArray.map}
        roughnessMap={landTexturesArray.roughnessMap}
        normalMap={landTexturesArray.normalMap}
        roughness={0.25}
        metalness={0.5}
      />
      </RoundedBox>


    {/* Закладдная дальнего края оси Y*/}
    <RoundedBox
      position={[updatedPosition[0], updatedPosition[1] + 0.033, updatedPosition[2] - 0.466]}  // Позиция вдоль оси Y (ближний край)
      args={[sizeLand + 0.001, 0.07, 0.07]}  // Размеры для RoundedBox
      radius={0}
      smoothness={4}
    >
      <meshStandardMaterial color="gray" />
    </RoundedBox>

      {/* Верхняя грань дальнего края оси Y*/}
      <RoundedBox 
      position={[updatedPosition[0], updatedPosition[1] + 0.14 / 2, updatedPosition[2] - 0.466]}  // Позиция над RoundedBox
      rotation={[-Math.PI / 2, 0, 0]}  // Поворот, чтобы плоскость легла горизонтально
      args={[sizeLand + 0.001, 0.07, 0.001]} 
      radius={0.002}
      smoothness={4}
      > 
      <meshStandardMaterial
        map={landTexturesArray.map}
        roughnessMap={landTexturesArray.roughnessMap}
        normalMap={landTexturesArray.normalMap}
        roughness={0.25}
        metalness={0.5}
      />
      </RoundedBox>


      {/* RoundedBox для левого края оси X (с учетом размера sizeLand и смещения на 3.5 см) */}
      <RoundedBox
        position={[updatedPosition[0] - sizeLand / 2 + 0.034, updatedPosition[1] + 0.07, updatedPosition[2]]}  // Смещаем на половину ширины sizeLand и отнимаем 3.5 см
        args={[0.86, 0.001, 0.07]}  // Длина 1 метр для левого края оси X
        radius={0.001}
        smoothness={1}
        rotation={[0, Math.PI / 2, 0]}  // Поворот на 90 градусов вокруг оси Y
      >
        <meshStandardMaterial
        map={landTexturesArray.map}
        roughnessMap={landTexturesArray.roughnessMap}
        normalMap={landTexturesArray.normalMap}
        roughness={0.25}
        metalness={0.5}
      />
      </RoundedBox>
      <RoundedBox
        position={[updatedPosition[0] - sizeLand / 2 + 0.034, updatedPosition[1] + 0.033, updatedPosition[2]]}  // Смещаем на половину ширины sizeLand и отнимаем 3.5 см
        args={[0.86, 0.07, 0.07]}  // Длина 1 метр для левого края оси X
        radius={0}
        smoothness={1}
        rotation={[0, Math.PI / 2, 0]}  // Поворот на 90 градусов вокруг оси Y
      >
        <meshStandardMaterial color="gray" />
      </RoundedBox>


      <RoundedBox
        position={[updatedPosition[0] + sizeLand / 2 - 0.034, updatedPosition[1] + 0.07, updatedPosition[2]]}  // Смещаем на половину ширины sizeLand и прибавляем 3.5 см
        args={[0.86, 0.001, 0.07]}  // Длина 1 метр для правого края оси X
        radius={0.001}
        smoothness={1}
        rotation={[0, Math.PI / 2, 0]}  // Поворот на 90 градусов вокруг оси Y
      >
      <meshStandardMaterial
        map={landTexturesArray.map}
        roughnessMap={landTexturesArray.roughnessMap}
        normalMap={landTexturesArray.normalMap}
        roughness={0.25}
        metalness={0.5}
      />
      </RoundedBox>      
      <RoundedBox
        position={[updatedPosition[0] + sizeLand / 2 - 0.034, updatedPosition[1] + 0.033, updatedPosition[2]]}  // Смещаем на половину ширины sizeLand и прибавляем 3.5 см
        args={[0.86, 0.07, 0.07]}  // Длина 1 метр для правого края оси X
        radius={0}
        smoothness={1}
        rotation={[0, Math.PI / 2, 0]}  // Поворот на 90 градусов вокруг оси Y
      >
      <meshStandardMaterial color="gray" />
      </RoundedBox>
      {/* RoundedBox для правого края оси X (с учетом размера sizeLand и смещения на 3.5 см) */}

    </>
  );
};



// Компонент для отображения портрета с возможностью перетаскивания
const DraggablePortret = ({ stelaPosition, portretSize, imageSrc, maskSrc, index }) => {
  const [dragOffset, setDragOffset] = useState([0, 0]);

  // Всегда вызываем useLoader, даже если imageSrc или maskSrc пустые
  const texture = useLoader(TextureLoader, imageSrc || '/textures/portret-woman.jpg'); // Заглушка по умолчанию
  const mask = useLoader(TextureLoader, maskSrc || '/textures/portret-mask2.png'); // Заглушка по умолчанию

  const bind = useDrag(({ offset: [x, y] }) => {
    setDragOffset([x / 100, -y / 100]);
  });

  const portretYOffset = 0.75 - index * 0.15; // Смещение портрета по оси Y
  const zOffset = 0.1;

  const updatedPosition = [
    stelaPosition[0] + dragOffset[0],
    stelaPosition[1] + dragOffset[1] + portretYOffset,
    stelaPosition[2] + zOffset,
  ];

  return (
    <Plane
      {...bind()}
      position={updatedPosition}
      args={[portretSize[0], portretSize[1]]} // Размер портрета 0.3 на 0.3
    >
      {/* Применяем текстуру и маску */}
      <meshBasicMaterial map={imageSrc ? texture : undefined} alphaMap={maskSrc ? mask : undefined} transparent={true} />
    </Plane>
  );
};


const DraggableText = ({ surname, stelaPosition, fontSize, color }) => {
  
  const [dragOffset, setDragOffset] = useState([0, 0]); // Смещение, заданное пользователем

  // Handler for drag event (перетаскивание текста)
  const bind = useDrag(({ offset: [x, y] }) => {
    setDragOffset([x / 100, -y / 100]);
  });

  // Смещение по осям Y и Z для центрирования текста и перемещения ближе к камере
  const textYOffset = 0.5;
  const zOffset = 0.1;

  // Позиция текста = позиция стелы + смещение от перетаскивания + смещения по Y и Z
  const updatedPosition = [
    stelaPosition[0] + dragOffset[0],
    stelaPosition[1] + dragOffset[1] + textYOffset,
    stelaPosition[2] + zOffset,
  ];

  return (
    <Text
      {...bind()} // Привязка обработчика перетаскивания
      position={updatedPosition}
      fontSize={fontSize} // Размер шрифта передаем как пропс
      color={color} // Установка цвета текста
      anchorX="center"
      anchorY="middle"
    >
      {surname}
    </Text>
  );
};

// Компонент для отображения имени с возможностью перетаскивания
const DraggableFirstName = ({ firstName, stelaPosition, firstNameFontSize, lineHeight, textAlignFirstName = 'center', color, index }) => {
  const [dragOffset, setDragOffset] = useState([0, 0]);

  const bind = useDrag(({ offset: [x, y] }) => {
    setDragOffset([x / 100, -y / 100]);
  });

  const textYOffset = 0.44 - index * 0.05; // Уменьшаем смещение по Y для каждого имени
  const zOffset = 0.1;

  const updatedPosition = [
    stelaPosition[0] + dragOffset[0],
    stelaPosition[1] + dragOffset[1] + textYOffset,
    stelaPosition[2] + zOffset,
  ];

  return (
    <Text
      {...bind()}
      position={updatedPosition}
      fontSize={firstNameFontSize}
      color={color} // Установка цвета текста
      anchorX="center"
      anchorY="middle"
      textAlign={textAlignFirstName}
      lineHeight={lineHeight}
      
    >
      {firstName}
    </Text>
  );
};

// Компонент для отображения даты с возможностью перетаскивания
const DraggableDate = ({ date, stelaPosition, dateFontSize, lineHeightDate, textAlignDate = 'center', color, index }) => {

  const [dragOffset, setDragOffset] = useState([0, 0]);

  const bind = useDrag(({ offset: [x, y] }) => {
    setDragOffset([x / 100, -y / 100]);
  });

  const textYOffset = 0.35 - index * 0.05; // Уменьшаем смещение по Y для каждой даты
  const zOffset = 0.1;

  const updatedPosition = [
    stelaPosition[0] + dragOffset[0],
    stelaPosition[1] + dragOffset[1] + textYOffset,
    stelaPosition[2] + zOffset,
  ];

  return (
    <Text
      {...bind()}
      position={updatedPosition}
      fontSize={dateFontSize}
      color={color} // Установка цвета текста
      anchorX="center"
      anchorY="middle"
      textAlign={textAlignDate}
      lineHeight={lineHeightDate}
    >
      {date}
    </Text>
  );
};



// Функция для загрузки текстур
const loadTextures = (material, repeatX = 1, repeatY = 1) => {
  const textureLoader = new THREE.TextureLoader();

  const map = textureLoader.load(material.baseColor);
  const roughnessMap = textureLoader.load(material.roughness);
  const normalMap = textureLoader.load(material.normalMap);

  map.wrapS = map.wrapT = THREE.RepeatWrapping;
  roughnessMap.wrapS = roughnessMap.wrapT = THREE.RepeatWrapping;
  normalMap.wrapS = normalMap.wrapT = THREE.RepeatWrapping;

  map.repeat.set(repeatX, repeatY);
  roughnessMap.repeat.set(repeatX, repeatY);
  normalMap.repeat.set(repeatX, repeatY);

  return { map, roughnessMap, normalMap };
};

// Начальное количество стел
const initialStela = {
  monumentIndex: 1,
  rp_1MaterialIndex: 0,
  surname: 'ФАМИЛИЯ',
  firstNames: ['Имя Отчество'],
  dates: ['01.01.1900-02.02.2000'], // Даты на стелах
  fontSize: 0.055,
  firstNameFontSize: 0.045,
  dateFontSize: 0.03, // Размер шрифта для дат
  lineHeight: 1.5, // Line-height для имен
  dateLineHeight: 1.5, // Инициализация для line-height даты
  textAlign: 'center', // Выравнивание для имени
  textAlignDate: 'center', // Выравнивание для даты
  textColor: 'white', // Добавляем цвет текста
  stelaRotation: 0, // начальный угол поворота стелы 
  portrets: [
    { size: [0.3, 0.3], imageSrc: '/textures/portret-woman.jpg', maskSrc: '/textures/portret-mask2.png' }
  ] // Добавляем изображение и маску по умолчанию для портретов
};



const App = () => {
  function CameraSetup() {
    const { camera } = useThree();
    useEffect(() => {
      camera.position.set(0, 1, 1.5);
      camera.lookAt(0, 0, 0);
    }, [camera]);
    return null;
  }

  const [color, setColor] = useState('white'); // Значение по умолчанию

  const [selectedMonument, setSelectedMonument] = useState(1); // Начальное значение 1
  const [rp_1MaterialIndex, setRp_1MaterialIndex] = useState(0); // Начальный индекс текстуры

  const handleMonumentChange = (event) => {
    setSelectedMonument(parseInt(event.target.value));
  };

  const handleTextureClick = (index) => {
    setRp_1MaterialIndex(index);
  };

  const handleThumbnailSelect = (index) => {
    setSelectedMonument(index); // Устанавливаем выбранный памятник
  };

  const [surname, setSurname] = useState('Фамилия'); // Состояние для фамилии

  const handleSurnameChange = (event) => {
    setSurname(event.target.value); // Изменяем фамилию при вводе
  };


  const [countX, setCountX] = useState(3); // Изначальное количество плиток по оси X
  const [countY, setCountY] = useState(3); // Изначальное количество плиток по оси Y
  const [cornerCubeHeight, setCornerCubeHeight] = useState(0.1); // Изначальная высота куба по углам (10 см)


  
  const tileSize = 0.3; // Размер одной плитки (30 см)
  const bevelSize = 0.007; // Размер фаски (0.5 см)
  const cornerCubeSize = 0.15; // Размер куба по углам (15x15 см)
  const cornerOffset = -0.1; // Смещение угловых кубов (7 см)
  const tileHeight = 0.03; // Высота плитки (3 см)
  const tileHeightZokol = 0.12; // Высота Цоколь (3 см)

  // Инициализируйте массив текстур с первой текстурой по умолчанию
  const defaultTextures = loadTextures(materials.GraniteTumba[0]); // Замените на вашу первую текстуру
  const initialTexturesArray = [defaultTextures];

  const [pedestalTexturesArray, setPedestalTexturesArray] = useState(initialTexturesArray);
  const [materialIndexArray, setMaterialIndexArray] = useState([0]);

  const updatePedestalTexture = (index, newMaterialIndex) => {
    const newTextures = [...pedestalTexturesArray];
    const { GraniteTumba } = materials;
    const pedestalMaterial = GraniteTumba[newMaterialIndex];

    if (pedestalMaterial) {
      newTextures[index] = loadTextures(pedestalMaterial, 2, 2);
    }

    setPedestalTexturesArray(newTextures);
    setMaterialIndexArray((prev) => {
      const newIndices = [...prev];
      newIndices[index] = newMaterialIndex;
      return newIndices;
    });
  };

  const [landTexturesArray, setLandTexturesArray] = useState(initialTexturesArray);
 

  const updateLandTexture = (index, newMaterialIndex) => {
    const newTextures = [...landTexturesArray];
    const { LandZvetnik } = materials;
    const landMaterial = LandZvetnik[newMaterialIndex];

    if (landMaterial) {
      newTextures[index] = loadTextures(landMaterial, 2, 2);
    }

    setLandTexturesArray(newTextures);
    setMaterialIndexArray((prev) => {
      const newIndices = [...prev];
      newIndices[index] = newMaterialIndex;
      return newIndices;
    });
  };

  const defaultTexturesZvetnik = loadTextures(materials.zvetnik[0]); // Замените на вашу первую текстуру
  const initialTexturesArrayZvetnik = [defaultTexturesZvetnik];
  const [zvetnikTexturesArray, setZvetnikTexturesArray] = useState(initialTexturesArrayZvetnik);
 
  const updateZvetnikTexture = (index, newMaterialIndex) => {
    const newTextures = [...zvetnikTexturesArray];
    const { zvetnik } = materials;
    const zvetnikMaterial = zvetnik[newMaterialIndex];

    if (zvetnikMaterial) {
      newTextures[index] = loadTextures(zvetnikMaterial, 2, 2);
    }

    setZvetnikTexturesArray(newTextures);
    setMaterialIndexArray((prev) => {
      const newIndices = [...prev];
      newIndices[index] = newMaterialIndex;
      return newIndices;
    });
  };


  const [tileTextures, setTileTextures] = useState({
    map: null,
    roughnessMap: null,
    normalMap: null,
  });

  const [tileMaterialIndex, setTileMaterialIndex] = useState(0); // Индекс выбранной текстуры для плитки

  useEffect(() => {
    const { GranitePlitka } = materials;
    const tileMaterial = GranitePlitka[tileMaterialIndex];
    setTileTextures(loadTextures(tileMaterial, 2, 2));
  }, [tileMaterialIndex]);


  const [stolbMaterialIndex, setStolbMaterialIndex] = useState(0); // Индекс выбранной текстуры для столбов
  const [stolbTextures, setStolbTextures] = useState({
    map: null,
    roughnessMap: null,
    normalMap: null,
  });


  useEffect(() => {
    const { GraniteStolb } = materials;
    const stolbMaterial = GraniteStolb[stolbMaterialIndex];
    setStolbTextures(loadTextures(stolbMaterial, 2, 1));
  }, [stolbMaterialIndex]);

  const [fenceMaterialIndex, setFenceMaterialIndex] = useState(0); // Индекс выбранной текстуры для ограды
  const [fenceTextures, setFenceTextures] = useState({
    map: null,
    roughnessMap: null,
    normalMap: null,
  });

  useEffect(() => {
    const { GraniteOgrada } = materials;
    const fenceMaterial = GraniteOgrada[fenceMaterialIndex];
    setFenceTextures(loadTextures(fenceMaterial, 2, 1));
  }, [fenceMaterialIndex]);


  const [zokolMaterialIndex, setZokolMaterialIndex] = useState(0); // Индекс выбранной текстуры для второго слоя плитки

  const [zokolTextures, setZokolTextures] = useState({
    map: null,
    roughnessMap: null,
    normalMap: null,
  });

  useEffect(() => {
    const { ZokolPlitka } = materials;
    const zokolMaterial = ZokolPlitka[zokolMaterialIndex];
    setZokolTextures(loadTextures(zokolMaterial, 2, 2));
  }, [zokolMaterialIndex]);

  
  const [edgeMaterialIndex, setEdgeMaterialIndex] = useState(0); // Индекс выбранной текстуры для крайних плиток
  const [edgeTextures, setEdgeTextures] = useState({
    map: null,
    roughnessMap: null,
    normalMap: null,
  });

  useEffect(() => {
    const { GraniteEdge } = materials; // Предположим, что GraniteEdge — это набор материалов для крайних плиток
    const edgeMaterial = GraniteEdge[edgeMaterialIndex];
    setEdgeTextures(loadTextures(edgeMaterial, 2, 2));
  }, [edgeMaterialIndex]);

  const handleIncreaseCountX = () => {
    setCountX((prev) => prev + 1);
  };

  const handleDecreaseCountX = () => {
    setCountX((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleIncreaseCountY = () => {
    setCountY((prev) => prev + 1);
  };

  const handleDecreaseCountY = () => {
    setCountY((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleIncreaseCornerCubeHeight = () => {
    setCornerCubeHeight((prev) => prev + 0.05); // Увеличение высоты на 5 см
  };

  const handleDecreaseCornerCubeHeight = () => {
    setCornerCubeHeight((prev) => (prev > 0.05 ? prev - 0.05 : prev)); // Уменьшение высоты на 5 см, минимальная высота 5 см
  };

  const [fenceHeight, setFenceHeight] = useState(0.1); // Высота ограды в метрах
  const [fenceThickness, setFenceThickness] = useState(0.1); // Толщина ограды в метрах

  const handleIncreaseFenceHeight = () => {
    setFenceHeight((prev) => prev + 0.01); // Увеличение на 1 см
  };
  
  const handleDecreaseFenceHeight = () => {
    setFenceHeight((prev) => (prev > 0.01 ? prev - 0.01 : prev)); // Уменьшение на 1 см, минимальная высота 1 см
  };
  
  const handleIncreaseFenceThickness = () => {
    setFenceThickness((prev) => prev + 0.01); // Увеличение на 1 см
  };
  
  const handleDecreaseFenceThickness = () => {
    setFenceThickness((prev) => (prev > 0.01 ? prev - 0.01 : prev)); // Уменьшение на 1 см, минимальная толщина 1 см
  };

  const [fencePositionY, setFencePositionY] = useState(0);
  const handleIncreaseFencePositionY = () => setFencePositionY(fencePositionY + 0.01);
  const handleDecreaseFencePositionY = () => setFencePositionY(fencePositionY - 0.01);

  const fenceY = tileHeight + fencePositionY;
  


  const cubes = [];
  const offsetX = (countX * tileSize) / 2 - tileSize / 2;
  const offsetY = (countY * tileSize) / 2 - tileSize / 2;

  const cornerPositions = [
    [-offsetX - tileSize / 2 - cornerOffset, tileHeight / 2 + cornerCubeHeight / 2, -offsetY - tileSize / 2 - cornerOffset], // Bottom-left
    [offsetX + tileSize / 2 + cornerOffset, tileHeight / 2 + cornerCubeHeight / 2, -offsetY - tileSize / 2 - cornerOffset],  // Bottom-right
    [-offsetX - tileSize / 2 - cornerOffset, tileHeight / 2 + cornerCubeHeight / 2, offsetY + tileSize / 2 + cornerOffset],  // Top-left
    [offsetX + tileSize / 2 + cornerOffset, tileHeight / 2 + cornerCubeHeight / 2, offsetY + tileSize / 2 + cornerOffset],   // Top-right
  ];

  
  
  //const layerOffsetY = -(tileHeightZokol - tileHeight); // Смещение второго слоя, чтобы верхний край цоколя совпадал с нижним краем плитки
  const layerOffsetY = -(tileHeightZokol - tileHeight) / 1.19; // Корректируем смещение второго слоя

    // Состояние для выбора между "Плитка" и "Трава"
    const [selectedOption, setSelectedOption] = useState('Плитка'); // По умолчанию выбрана "Плитка"

    // Обработчик для смены опции в выпадающем списке
    const handleSelectChange = (e) => {
      setSelectedOption(e.target.value);
    };

      // Определяем центр плиток по оси X
  const centerX = Math.floor(countX / 2);

  // Создаем плитки
  for (let i = 0; i < countX; i++) {
    for (let j = 0; j < countY; j++) {
      const isEdge = i === 0 || i === countX - 1 || j === 0 || j === countY - 1;

      // Определяем, скрывать ли плитку. Скрываем только плитки правее середины по X.
      const isRightOfCenterX = i >= Math.floor(countX / 2);

      // Скрываем плитки правее середины по X, если выбрана "Трава"
      const shouldHide = selectedOption === 'Трава' && !isEdge && isRightOfCenterX;

      const textures = isEdge ? edgeTextures : tileTextures;

      cubes.push(
        <RoundedBox
          key={`tile-${i}-${j}`}
          args={[tileSize, tileHeight, tileSize]} // Размеры плитки
          radius={bevelSize} // Радиус скругления (фаски)
          smoothness={0.1} // Сглаживание фаски
          position={[i * tileSize - offsetX, 0, j * tileSize - offsetY]}
          visible={!shouldHide} // Скрываем плитки, если выбрана "Трава"
        >
          <meshStandardMaterial
            map={textures.map}
            roughnessMap={textures.roughnessMap}
            normalMap={textures.normalMap}
            roughness={0.25} // Установка уровня шероховатости (по необходимости)
            metalness={0.5} // Установка уровня металлического свойства
          />
        </RoundedBox>
      );
    }
  }

  // Добавляем новый бокс, который растягивается на скрытую область (только по внутренним краям)
  if (selectedOption === 'Трава') {
    const hiddenAreaWidth = (countX - centerX - 1) * tileSize; // Убираем только одну плитку с правой стороны
    const hiddenAreaPositionX = (centerX * tileSize - offsetX) + hiddenAreaWidth / 2 - tileSize / 2; // Центрируем по X

    // Добавляем бокс для закрытия правой части
    cubes.push(
      <RoundedBox
        key="hidden-area"
        args={[hiddenAreaWidth, tileHeight, (countY - 2) * tileSize]} // Убираем по одному ряду плиток с каждого края по оси Y
        radius={bevelSize} // Радиус скругления (фаски)
        smoothness={0.1} // Сглаживание фаски
        position={[hiddenAreaPositionX, 0, 0]} // Центрируем по X и Z (по оси Z остаемся в центре)
      >
        <meshStandardMaterial
          color="green" // Можно применить текстуру для травы
          roughness={0.25}
          metalness={0.5}
        />
      </RoundedBox>
    );
  }

  // Создаем второй слой плиток, смещенный вниз
  for (let i = 0; i < countX; i++) {
    for (let j = 0; j < countY; j++) {
      cubes.push(
        <RoundedBox
          key={`tile-zokol-${i}-${j}`}
          args={[tileSize, tileHeightZokol, tileSize]} // Размеры плитки
          radius={bevelSize} // Радиус скругления (фаски)
          smoothness={0.1} // Сглаживание фаски
          position={[i * tileSize - offsetX, layerOffsetY, j * tileSize - offsetY]}
        >
        <meshStandardMaterial
          map={zokolTextures.map}
          roughnessMap={zokolTextures.roughnessMap}
          normalMap={zokolTextures.normalMap}
          roughness={0.25}
          metalness={0.5}
        />
        </RoundedBox>
      );
    }
  }


// Условие для отображения угловых столбов
if (countX > 3 && countY > 3) {
  // Добавление угловых столбов
  cornerPositions.forEach((position, index) => {
    cubes.push(
      <RoundedBox
        key={`stolb-${index}`}
        args={[cornerCubeSize, cornerCubeHeight, cornerCubeSize]} // Размеры столбов
        radius={bevelSize} // Радиус скругления (фаски) для столбов
        smoothness={4} // Сглаживание фаски
        position={position}
      >
        <meshStandardMaterial
          map={stolbTextures.map}
          roughnessMap={stolbTextures.roughnessMap}
          normalMap={stolbTextures.normalMap}
          roughness={0.25}
          metalness={0.5}
        />
      </RoundedBox>
    );
  });

  // Добавление промежуточных столбов по оси X
  if (countX > 9) {
    const numIntermediateColumnsX = Math.floor((countX - 1) / 9) * 2;
    const spacingX = (offsetX * 2) / (numIntermediateColumnsX + 1);
  
    let previousPosX = -offsetX; // Начальная позиция для углового столба
  
    for (let i = 1; i <= numIntermediateColumnsX; i++) {
      const posX = i * spacingX - offsetX;
  
      const midPositionsX = [
        [posX, tileHeight / 2 + cornerCubeHeight / 2, -offsetY - tileSize / 2 - cornerOffset], // Middle-bottom
        [posX, tileHeight / 2 + cornerCubeHeight / 2, offsetY + tileSize / 2 + cornerOffset],  // Middle-top
      ];
  
      midPositionsX.forEach((position, index) => {
        cubes.push(
          <RoundedBox
            key={`midX-${i}-${index}`}
            args={[cornerCubeSize, cornerCubeHeight, cornerCubeSize]} // Размеры куба по середине оси X
            radius={bevelSize} // Радиус скругления (фаски)
            smoothness={4} // Сглаживание фаски
            position={position}
          >
            <meshStandardMaterial
              map={stolbTextures.map}
              roughnessMap={stolbTextures.roughnessMap}
              normalMap={stolbTextures.normalMap}
              roughness={0.25}
              metalness={0.5}
            />
          </RoundedBox>
        );
      });

      // Добавляем забор между текущим и предыдущим промежуточным столбом
      if (i > 1) {
        const fenceLengthX = posX - previousPosX - 0.15; // Длина забора между столбами
        const fencePositionsX = [
          // Нижняя ограда
          [(previousPosX + posX) / 2, tileHeight / 2 + fenceHeight / 2, -offsetY - tileSize / 4 - cornerOffset - cornerCubeSize / 2],
          // Верхняя ограда
          [(previousPosX + posX) / 2, tileHeight / 2 + fenceHeight / 2, offsetY + tileSize / 4 + cornerOffset + cornerCubeSize / 2],
        ];
  
        fencePositionsX.forEach((position, index) => {
          cubes.push(
            <RoundedBox
              key={`fenceX-mid-${i}-${index}`}
              args={[fenceLengthX, fenceHeight, fenceThickness]} // Размеры забора
              radius={0.003} // Радиус скругления (фаски)
              smoothness={1} // Сглаживание фаски
              position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция с учетом изменения по оси Y
            >
              <meshStandardMaterial
                map={fenceTextures.map}
                roughnessMap={fenceTextures.roughnessMap}
                normalMap={fenceTextures.normalMap}
                roughness={0.25}
                metalness={0.5}
              />
            </RoundedBox>
          );
        });
      }
  
      // Сохраняем текущую позицию как предыдущую для следующей итерации
      previousPosX = posX;
    }

      // Добавляем забор от углового левого столба до первого промежуточного
      const firstIntermediatePosX = spacingX - offsetX - 0.1;
      const fenceLengthFromCornerX = firstIntermediatePosX + offsetX;
      
      const cornerToFirstMidFencePositionsX = [
        [(firstIntermediatePosX - offsetX) / 2 + 0.025, tileHeight / 2 + fenceHeight / 2, -offsetY - tileSize / 4 - cornerOffset - cornerCubeSize / 2], // Нижняя ограда
        [(firstIntermediatePosX - offsetX) / 2 + 0.025, tileHeight / 2 + fenceHeight / 2, offsetY + tileSize / 4 + cornerOffset + cornerCubeSize / 2],  // Верхняя ограда
      ];

      cornerToFirstMidFencePositionsX.forEach((position, index) => {
        cubes.push(
          <RoundedBox
            key={`fenceX-corner-first-mid-${index}`}
            args={[fenceLengthFromCornerX, fenceHeight, fenceThickness]} // Размеры забора
            radius={0.003} // Радиус скругления (фаски)
            smoothness={1} // Сглаживание фаски
            position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция с учетом изменения по оси Y
          >
            <meshStandardMaterial
              map={fenceTextures.map}
              roughnessMap={fenceTextures.roughnessMap}
              normalMap={fenceTextures.normalMap}
              roughness={0.25}
              metalness={0.5}
            />
          </RoundedBox>
        );
      });

      // Добавляем забор от последнего промежуточного столба до углового правого столба
      const lastIntermediatePosX = numIntermediateColumnsX * spacingX - offsetX;
      const fenceLengthToLastCornerX = offsetX - lastIntermediatePosX - 0.1;
      const lastMidToCornerFencePositionsX = [
        [(lastIntermediatePosX + offsetX) / 2 + 0.025, tileHeight / 2 + fenceHeight / 2, -offsetY - tileSize / 4 - cornerOffset - cornerCubeSize / 2], // Нижняя ограда
        [(lastIntermediatePosX + offsetX) / 2 + 0.025, tileHeight / 2 + fenceHeight / 2, offsetY + tileSize / 4 + cornerOffset + cornerCubeSize / 2],  // Верхняя ограда
      ];

      lastMidToCornerFencePositionsX.forEach((position, index) => {
        cubes.push(
          <RoundedBox
            key={`fenceX-last-mid-corner-${index}`}
            args={[fenceLengthToLastCornerX, fenceHeight, fenceThickness]} // Размеры забора
            radius={0.003} // Радиус скругления (фаски)
            smoothness={1} // Сглаживание фаски
            position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция с учетом изменения по оси Y
          >
            <meshStandardMaterial
              map={fenceTextures.map}
              roughnessMap={fenceTextures.roughnessMap}
              normalMap={fenceTextures.normalMap}
              roughness={0.25}
              metalness={0.5}
            />
          </RoundedBox>
        );
      });

      

  } else if (countX >= 6) {
    // Если меньше или равно 9 плиток по X
    const positionsX = [
      [0, tileHeight / 2 + cornerCubeHeight / 2, -offsetY - tileSize / 2 - cornerOffset], // Центр-сзади
      [0, tileHeight / 2 + cornerCubeHeight / 2, offsetY + tileSize / 2 + cornerOffset],  // Центр-спереди
    ];
  
    positionsX.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`midX-${index}`}
          args={[cornerCubeSize, cornerCubeHeight, cornerCubeSize]} // Размеры центрального куба по оси X
          radius={bevelSize} // Радиус скругления (фаски)
          smoothness={4} // Сглаживание фаски
          position={position}
        >
          <meshStandardMaterial
            map={stolbTextures.map}
            roughnessMap={stolbTextures.roughnessMap}
            normalMap={stolbTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });
  
    // Добавление ограды от углового до среднего столба
    const fenceLengthX = Math.abs(offsetX - 0.102); // Длина ограды от углового до среднего столба
    const fencePositionsX = [
      [offsetX - (fenceLengthX / 2) - 0.025, tileHeight / 2 + fenceHeight / 2, -offsetY - tileSize / 4 - cornerOffset - cornerCubeSize / 2], // Bottom-right to middle
      [-offsetX + (fenceLengthX / 2) + 0.025, tileHeight / 2 + fenceHeight / 2, -offsetY - tileSize / 4 - cornerOffset - cornerCubeSize / 2], // Middle to bottom-left
      [offsetX - (fenceLengthX / 2) - 0.025, tileHeight / 2 + fenceHeight / 2, offsetY + tileSize / 4 + cornerOffset + cornerCubeSize / 2],  // Middle to top-right
      [-offsetX + (fenceLengthX / 2) + 0.025, tileHeight / 2 + fenceHeight / 2, offsetY + tileSize / 4 + cornerOffset + cornerCubeSize / 2],  // Top-left to middle
    ];
  
    fencePositionsX.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`fenceX-${index}`}
          args={[fenceLengthX, fenceHeight, fenceThickness]} // Размеры ограды
          radius={0.003} // Радиус скругления (фаски)
          smoothness={1} // Сглаживание фаски
          position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция с учетом изменения по оси Y
        >
          <meshStandardMaterial
            map={fenceTextures.map}
            roughnessMap={fenceTextures.roughnessMap}
            normalMap={fenceTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });

  } else if (countX <= 5) {
    // Если средних столбов нет, добавляем целую ограду от одного угла до другого
    const fenceLengthX = offsetX * 2 - 0.05; // Длина ограды равна расстоянию между угловыми столбами
    const fencePositionsX = [
      [0, tileHeight / 2 + fenceHeight / 2, -offsetY - tileSize / 4 - cornerOffset - cornerCubeSize / 2], // Нижняя ограда
      [0, tileHeight / 2 + fenceHeight / 2, offsetY + tileSize / 4 + cornerOffset + cornerCubeSize / 2],  // Верхняя ограда
    ];
  
    fencePositionsX.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`fenceX-no-mid-${index}`}
          args={[fenceLengthX, fenceHeight, fenceThickness]} // Размеры ограды
          radius={0.003} // Радиус скругления (фаски)
          smoothness={1} // Сглаживание фаски
          position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция ограды
        >
          <meshStandardMaterial
            map={fenceTextures.map}
            roughnessMap={fenceTextures.roughnessMap}
            normalMap={fenceTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });
  }
  

  // Аналогичное условие для оси Y
  if (countY > 9) {
    const numIntermediateColumnsY = Math.floor((countY - 1) / 9) * 2;
    const spacingY = (offsetY * 2) / (numIntermediateColumnsY + 1);
    
    let previousPosY = -offsetY; // Начальная позиция для углового столба
    
    for (let i = 1; i <= numIntermediateColumnsY; i++) {
      const posY = i * spacingY - offsetY;
    
      const midPositionsY = [
        [-offsetX - tileSize / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + cornerCubeHeight / 2, posY], // Left-middle
        [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + cornerCubeHeight / 2, posY],  // Right-middle
      ];
    
      midPositionsY.forEach((position, index) => {
        cubes.push(
          <RoundedBox
            key={`midY-${i}-${index}`}
            args={[cornerCubeSize, cornerCubeHeight, cornerCubeSize]} // Размеры куба по середине оси Y
            radius={bevelSize} // Радиус скругления (фаски)
            smoothness={4} // Сглаживание фаски
            position={position}
          >
            <meshStandardMaterial
              map={stolbTextures.map}
              roughnessMap={stolbTextures.roughnessMap}
              normalMap={stolbTextures.normalMap}
              roughness={0.25}
              metalness={0.5}
            />
          </RoundedBox>
        );
      });
    
      // Добавляем забор между текущим и предыдущим промежуточным столбом
      if (i > 1) {
        const fenceLengthY = posY - previousPosY - 0.15; // Длина забора между столбами
        const fencePositionsY = [
          // Левая ограда
          [-offsetX - tileSize / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, (previousPosY + posY) / 2],
          // Правая ограда
          [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, (previousPosY + posY) / 2],
        ];
    
        fencePositionsY.forEach((position, index) => {
          cubes.push(
            <RoundedBox
              key={`fenceY-mid-${i}-${index}`}
              args={[fenceThickness, fenceHeight, fenceLengthY]} // Размеры забора
              radius={0.003} // Радиус скругления (фаски)
              smoothness={1} // Сглаживание фаски
              position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция с учетом изменения по оси Y
            >
              <meshStandardMaterial
                map={fenceTextures.map}
                roughnessMap={fenceTextures.roughnessMap}
                normalMap={fenceTextures.normalMap}
                roughness={0.25}
                metalness={0.5}
              />
            </RoundedBox>
          );
        });
      }
    
      // Сохраняем текущую позицию как предыдущую для следующей итерации
      previousPosY = posY;
    }

    // Добавляем забор от углового ближнего столба до первого промежуточного на оси Y
    const firstIntermediatePosY = spacingY - offsetY - 0.1;
    const fenceLengthFromCornerY = firstIntermediatePosY + offsetY;

    const cornerToFirstMidFencePositionsY = [
      // Левая ограда
      [-offsetX - tileSize / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, (firstIntermediatePosY - offsetY) / 2 + 0.025],
      // Правая ограда
      [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, (firstIntermediatePosY - offsetY) / 2 + 0.025],
    ];

    cornerToFirstMidFencePositionsY.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`fenceY-corner-first-mid-${index}`}
          args={[fenceThickness, fenceHeight, fenceLengthFromCornerY]} // Размеры забора
          radius={0.003} // Радиус скругления (фаски)
          smoothness={1} // Сглаживание фаски
          position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция с учетом изменения по оси Y
        >
          <meshStandardMaterial
            map={fenceTextures.map}
            roughnessMap={fenceTextures.roughnessMap}
            normalMap={fenceTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });

    // Добавляем забор от последнего промежуточного столба до углового дальнего столба на оси Y
    const lastIntermediatePosY = numIntermediateColumnsY * spacingY - offsetY;
    const fenceLengthToLastCornerY = offsetY - lastIntermediatePosY - 0.1;

    const lastMidToCornerFencePositionsY = [
      // Левая ограда
      [-offsetX - tileSize / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, (lastIntermediatePosY + offsetY) / 2 + 0.025],
      // Правая ограда
      [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, (lastIntermediatePosY + offsetY) / 2 + 0.025],
    ];

    lastMidToCornerFencePositionsY.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`fenceY-last-mid-corner-${index}`}
          args={[fenceThickness, fenceHeight, fenceLengthToLastCornerY]} // Размеры забора
          radius={0.003} // Радиус скругления (фаски)
          smoothness={1} // Сглаживание фаски
          position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция с учетом изменения по оси Y
        >
          <meshStandardMaterial
            map={fenceTextures.map}
            roughnessMap={fenceTextures.roughnessMap}
            normalMap={fenceTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });


  } else if (countY >= 6) {
    const positionsY = [
      [-offsetX - tileSize / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + cornerCubeHeight / 2, 0], // Центр-слева
      [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + cornerCubeHeight / 2, 0],  // Центр-справа
    ];

    positionsY.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`midY-${index}`}
          args={[cornerCubeSize, cornerCubeHeight, cornerCubeSize]} // Размеры центрального куба по оси Y
          radius={bevelSize} // Радиус скругления (фаски)
          smoothness={4} // Сглаживание фаски
          position={position}
        >
          <meshStandardMaterial
            map={stolbTextures.map}
            roughnessMap={stolbTextures.roughnessMap}
            normalMap={stolbTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });

    // Добавление ограды от углового до среднего столба по оси Y


    const fenceLengthYFromCornerToMid = offsetY - 0.1; // Длина ограды от углового до среднего столба
    const fencePositionsY = [
      [-offsetX - tileSize  / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, -fenceLengthYFromCornerToMid / 2 - 0.074],
      [-offsetX - tileSize / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, fenceLengthYFromCornerToMid / 2 + 0.074],
      [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, -fenceLengthYFromCornerToMid / 2 - 0.074],
      [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, fenceLengthYFromCornerToMid / 2 + 0.074],
    ];

    fencePositionsY.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`fenceY-corner-to-mid-${index}`}
          args={[fenceThickness, fenceHeight, fenceLengthYFromCornerToMid]} // Размеры ограды
          radius={0.003} // Радиус скругления (фаски)
          smoothness={1} // Сглаживание фаски
          position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция ограды
        >
          <meshStandardMaterial
            map={fenceTextures.map}
            roughnessMap={fenceTextures.roughnessMap}
            normalMap={fenceTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });




  } else if (countY <= 5) {

    // Если средних столбов нет, добавляем целую ограду от одного угла до другого вдоль оси Y
    const fenceLengthY = offsetY * 2 - 0.05; // Длина ограды равна расстоянию между угловыми столбами
    const fencePositionsY = [
      [-offsetX - tileSize / 4 - cornerOffset - cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, 0], // Левая ограда
      [offsetX + tileSize / 4 + cornerOffset + cornerCubeSize / 2, tileHeight / 2 + fenceHeight / 2, 0],  // Правая ограда
    ];

    fencePositionsY.forEach((position, index) => {
      cubes.push(
        <RoundedBox
          key={`fenceY-no-mid-${index}`}
          args={[fenceThickness, fenceHeight, fenceLengthY]} // Размеры ограды
          radius={0.003} // Радиус скругления (фаски)
          smoothness={1} // Сглаживание фаски
          position={[position[0], fencePositionY + fenceHeight / 2, position[2]]} // Позиция ограды
        >
          <meshStandardMaterial
            map={fenceTextures.map}
            roughnessMap={fenceTextures.roughnessMap}
            normalMap={fenceTextures.normalMap}
            roughness={0.25}
            metalness={0.5}
          />
        </RoundedBox>
      );
    });

  }
}

  const [stelas, setStelas] = useState([initialStela]); // Массив стел
  const [stelaSpacing, setStelaSpacing] = useState(0.6); // Расстояние по умолчанию 0.6 м
  const [alignment, setAlignment] = useState('center'); // Выравнивание: 'center' или 'left'
  const leftEdgePositionX = -offsetX + 0.6; // Координата по X для левого края плитки
  const totalStelas = stelas.length; // Общее количество стел
  const centerOffset = (totalStelas - 1) * stelaSpacing / 2;
  const [pedestalSizes, setPedestalSizes] = useState([0.6]); // Инициализация размеров тумб
  const [landSizes, setLandSizes] = useState([0.6]); // Инициализация размеров тумб

  // Определение выравнивания: по центру или по левому краю плитки
  const calculatePositionX = (index) => {
    if (alignment === 'left') {
      return leftEdgePositionX + index * stelaSpacing;
    } else {
      return index * stelaSpacing - centerOffset; // По умолчанию выравнивание по центру
    }
  };

  const handleIncreaseSpacing = () => {
    setStelaSpacing(prevSpacing => prevSpacing + 0.05); // Увеличение на 5 см
  };

  const handleDecreaseSpacing = () => {
    setStelaSpacing(prevSpacing => Math.max(prevSpacing - 0.05, 0)); // Уменьшение на 5 см, не меньше 0
  };

  const updateStela = (index, updatedStela) => {
    setStelas(prevStelas => prevStelas.map((stela, i) => (i === index ? { ...stela, ...updatedStela } : stela)));
  };

  const addStela = () => {
    setStelas((prevStelas) => [
      ...prevStelas,
      { ...initialStela },
    ]);
    // Добавление текстуры по умолчанию для новой стелы
    setPedestalTexturesArray((prevTextures) => [
      ...prevTextures,
      loadTextures(materials.GraniteTumba[0]), // Текстура по умолчанию
    ]);
    setMaterialIndexArray((prevIndices) => [...prevIndices, 0]);
  
    // Добавляем новый размер тумбы по умолчанию
    setPedestalSizes((prevSizes) => [...prevSizes, 0.6]); // 0.6 — размер по умолчанию

        // Добавление текстуры по умолчанию для новой стелы
        setLandTexturesArray((prevTextures) => [
          ...prevTextures,
          loadTextures(materials.LandZvetnik[0]), // Текстура по умолчанию
        ]);
        setMaterialIndexArray((prevIndices) => [...prevIndices, 0]);
      
        // Добавляем новый размер тумбы по умолчанию
        setLandSizes((prevSizes) => [...prevSizes, 0.6]); // 0.6 — размер по умолчанию

                // Добавление текстуры по умолчанию для новой стелы
                setZvetnikTexturesArray((prevTextures) => [
                  ...prevTextures,
                  loadTextures(materials.zvetnik[0]), // Текстура по умолчанию
                ]);
                setMaterialIndexArray((prevIndices) => [...prevIndices, 0]);
  };

  const removeStela = (index) => {
    setStelas(stelas.filter((_, i) => i !== index)); // Удаляем стелу по индексу
  };


  // размер шрифта
  const [fontSize, setFontSize] = useState(0.055); // Начальный размер шрифта
  const handleIncreaseFontSize = (index) => {
    setStelas(prevStelas =>
      prevStelas.map((stela, i) =>
        i === index ? { ...stela, fontSize: stela.fontSize + 0.005 } : stela
      )
    );
  };
  
  const handleDecreaseFontSize = (index) => {
    setStelas(prevStelas =>
      prevStelas.map((stela, i) =>
        i === index ? { ...stela, fontSize: Math.max(stela.fontSize - 0.005, 0.005) } : stela
      )
    );
  };

  const addFirstName = (stelaIndex) => {
    console.log(`addFirstName called for stelaIndex: ${stelaIndex}`);
    setStelas((prevStelas) => {
      const updatedStelas = prevStelas.map((stela, index) => {
        if (index === stelaIndex) {
          if (stela.firstNames.length < 4) {
            return {
              ...stela,
              firstNames: [...stela.firstNames, 'Имя Отчество'], // Добавляем "Имя Отчество" по умолчанию
            };
          }
        }
        return stela;
      });
  
      console.log('Updated stelas:', updatedStelas);
      return updatedStelas;
    });
  };
  

  const removeFirstName = (stelaIndex, nameIndex) => {
    setStelas(prevStelas => {
      const updatedStela = { ...prevStelas[stelaIndex] };
      updatedStela.firstNames.splice(nameIndex, 1); // Удаляем имя по индексу
      return prevStelas.map((stela, i) => (i === stelaIndex ? updatedStela : stela));
    });
  };

  const handleIncreaseFirstNameFontSize = (index) => {
    setStelas(prevStelas =>
      prevStelas.map((stela, i) =>
        i === index ? { ...stela, firstNameFontSize: stela.firstNameFontSize + 0.005 } : stela
      )
    );
  };
  
  const handleDecreaseFirstNameFontSize = (index) => {
    setStelas(prevStelas =>
      prevStelas.map((stela, i) =>
        i === index ? { ...stela, firstNameFontSize: Math.max(stela.firstNameFontSize - 0.005, 0.005) } : stela
      )
    );
  };


// line-height межстрочный интервал
  const handleDecreaseLineHeight = (index) => {
    setStelas((prevStelas) =>
      prevStelas.map((stela, idx) =>
        idx === index ? { ...stela, lineHeight: Math.max(0.1, stela.lineHeight - 0.1) } : stela
      )
    );
  };
  
  const handleIncreaseLineHeight = (index) => {
    setStelas((prevStelas) =>
      prevStelas.map((stela, idx) =>
        idx === index ? { ...stela, lineHeight: stela.lineHeight + 0.1 } : stela
      )
    );
  };

// дата
  const handleIncreaseDateFontSize = (index) => {
    setStelas(prevStelas =>
      prevStelas.map((stela, i) =>
        i === index ? { ...stela, dateFontSize: stela.dateFontSize + 0.005 } : stela
      )
    );
  };
  
  const handleDecreaseDateFontSize = (index) => {
    setStelas(prevStelas =>
      prevStelas.map((stela, i) =>
        i === index ? { ...stela, dateFontSize: Math.max(stela.dateFontSize - 0.005, 0.005) } : stela
      )
    );
  };

  const addDate = (stelaIndex) => {
    setStelas((prevStelas) => {
      const updatedStelas = prevStelas.map((stela, index) => {
        if (index === stelaIndex && stela.dates.length < 4) {
          return { ...stela, dates: [...stela.dates, '01.01.1900-02.02.2000'] };
        }
        return stela;
      });
      return updatedStelas;
    });
  };

  // line-height межстрочный интервал для дат
const handleDecreaseDateLineHeight = (index) => {
  setStelas((prevStelas) =>
    prevStelas.map((stela, idx) =>
      idx === index ? { ...stela, dateLineHeight: Math.max(0.1, stela.dateLineHeight - 0.1) } : stela
    )
  );
};

const handleIncreaseDateLineHeight = (index) => {
  setStelas((prevStelas) =>
    prevStelas.map((stela, idx) =>
      idx === index ? { ...stela, dateLineHeight: stela.dateLineHeight + 0.1 } : stela
    )
  );
};


const removeDate = (stelaIndex, dateIndex) => {
  setStelas(prevStelas => {
    const updatedStela = { ...prevStelas[stelaIndex] };
    updatedStela.dates.splice(dateIndex, 1);
    return prevStelas.map((stela, i) => (i === stelaIndex ? updatedStela : stela));
  });
};

// портрет

const addPortret = (stelaIndex) => {
  setStelas((prevStelas) => {
    const updatedStelas = prevStelas.map((stela, index) => {
      if (index === stelaIndex && stela.portrets.length < 4) {
        return { 
          ...stela, 
          portrets: [
            ...stela.portrets, 
        
            { size: [0.3, 0.3], imageSrc: '/textures/portret-woman.jpg', maskSrc: '/textures/portret-mask2.png' }
          ] 
        };
      }
      return stela;
    });
    return updatedStelas;
  });
};


const removePortret = (stelaIndex, portretIndex) => {
  setStelas(prevStelas => {
    const updatedStela = { ...prevStelas[stelaIndex] };
    updatedStela.portrets.splice(portretIndex, 1); // Удаляем портрет
    return prevStelas.map((stela, i) => (i === stelaIndex ? updatedStela : stela));
  });
};

const updatePortretImage = (stelaIndex, portretIndex, newImageSrc, newMaskSrc) => {
  setStelas((prevStelas) => {
    const updatedStelas = [...prevStelas];
    const updatedPortrets = [...updatedStelas[stelaIndex].portrets];
    updatedPortrets[portretIndex] = {
      ...updatedPortrets[portretIndex],
      imageSrc: newImageSrc,
      maskSrc: newMaskSrc || updatedPortrets[portretIndex].maskSrc, // Обновляем только изображение, если маска не передана
    };
    updatedStelas[stelaIndex].portrets = updatedPortrets;
    return updatedStelas;
  });
};

const handleImageUpload = (e, stelaIndex, portretIndex) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onload = (event) => {
    const imageSrc = event.target.result; // Получаем путь к изображению
    updatePortretImage(stelaIndex, portretIndex, imageSrc, null); // Обновляем только изображение, оставляя маску
  };

  reader.readAsDataURL(file); // Читаем файл как Data URL
};

//изменения размеров тумбы



const handleIncreasePedestalSize = (index) => {
  setPedestalSizes((prevSizes) =>
    prevSizes.map((size, i) => (i === index ? size + 0.05 : size))
  );
};

const handleDecreasePedestalSize = (index) => {
  setPedestalSizes((prevSizes) =>
    prevSizes.map((size, i) => (i === index ? Math.max(0.1, size - 0.05) : size))
  );
};

const handleIncreaseLandSize = (index) => {
  setLandSizes((prevSizes) =>
    prevSizes.map((size, i) => (i === index ? size + 0.05 : size))
  );
};

const handleDecreaseLandSize = (index) => {
  setLandSizes((prevSizes) =>
    prevSizes.map((size, i) => (i === index ? Math.max(0.1, size - 0.05) : size))
  );
};

{/* поворот стел на 180 градусов */}
const handleRotationChange = (index, direction) => {
  const updatedStelas = stelas.map((stela, stelaIndex) => {
    if (stelaIndex === index) {
      const rotationChange = direction === 'right' ? Math.PI : -Math.PI; // +180 градусов для вправо, -180 для влево
      return { ...stela, stelaRotation: stela.stelaRotation + rotationChange };
    }
    return stela;
  });
  setStelas(updatedStelas);
};

  return (
    <div className="construktor">
      <Canvas gl={{ preserveDrawingBuffer: true }}>
      <ambientLight intensity={0.8} />  
        {/* <pointLight position={[10, 10, 10]} intensity={0.1} /> */}
        <CameraSetup />
        {/* OrbitControls включается/выключается на основе controlsEnabled */}
        <OrbitControls enableZoom={true} enablePan={true} />
        <Background hdrBackgroundPath="/textures/bg.hdr" hdrEnvironmentPath="/textures/envMap.hdr" />

        {/* Отображение плиток, угловых кубов и оград */}
        {cubes}
        {/* Отображение стел */}
        {stelas.map((stela, index) => {

          const stelaPosition = [calculatePositionX(index), 0.16, -offsetY + 0.2]; // Позиция стелы с корректировкой по оси Y
          const pedestalSize = pedestalSizes[index]; // Получаем размер для тумбы
          const landSize = landSizes[index]; // Получаем размер для земли в цветнике

          return (
            <React.Fragment key={index}>
              <Pedestal
                stelaPosition={stelaPosition}
                index={index}
                pedestalTexturesArray={pedestalTexturesArray[index] || defaultTextures} // Используем текстуру по умолчанию, если текстуры нет
                sizePedestal={pedestalSize} // Передаем размер
              />
              <Land
                stelaPosition={stelaPosition}
                index={index}
                landTexturesArray={landTexturesArray[index] || defaultTextures} // Используем текстуру по умолчанию, если текстуры нет
                zvetnikTexturesArray={zvetnikTexturesArray[index] || defaultTextures} // Используем текстуру по умолчанию, если текстуры нет
                sizeLand={landSize} // Передаем размер
              />
            {stela.portrets.map((portret, portretIndex) => (
              <DraggablePortret
                key={portretIndex}
                stelaPosition={stelaPosition}
                portretSize={portret.size}
                imageSrc={portret.imageSrc} // Передаем путь к изображению
                maskSrc={portret.maskSrc} // Передаем путь к маске
                index={portretIndex}
              />
            ))}
            <DraggableText
              surname={stela.surname}
              stelaPosition={stelaPosition}
              fontSize={stela.fontSize}
              color={stela.textColor} // Применяем цвет
            />
            {Array.isArray(stela.firstNames) && stela.firstNames.map((firstName, nameIndex) => (
              <DraggableFirstName
                key={nameIndex}
                firstName={firstName}
                stelaPosition={stelaPosition}
                fontSize={stela.fontSize} // Передаем размер шрифта для фамилий
                firstNameFontSize={stela.firstNameFontSize} // Передаем размер шрифта для имен
                textAlignFirstName={stela.textAlign} // Передача правильного выравнивания // Выравнивание текста для имени
                lineHeight={stela.lineHeight} // Убедитесь, что передаете lineHeight здесь
                index={nameIndex} // Передаем индекс
                color={stela.textColor} // Передаем цвет текста
              />
            ))}
            {stela.dates.map((date, dateIndex) => (
              <DraggableDate
                key={dateIndex}
                date={date}
                stelaPosition={stelaPosition}
                dateFontSize={stela.dateFontSize}
                lineHeightDate={stela.dateLineHeight} // Исправлено здесь
                textAlignDate={stela.textAlignDate}
                index={dateIndex}
                color={stela.textColor} // Применяем цвет
              />
            ))}
            

            <VerticalSstela 
              key={index}
              rp_1MaterialIndex={stela.rp_1MaterialIndex}
              monumentIndex={stela.monumentIndex}
              position={stelaPosition}
              stelaRotation={stela.stelaRotation}
            />

          </React.Fragment>
          );
        })}

      </Canvas>

      <div className="controls-content">

      {/* Управление портретами */}
  

  

        {/* Выбор выравнивания */}
        <div className="wraper-plitka">
          <div>Выравнивание стел:</div>
          <div>
            <label>
              <input
                type="radio"
                value="center"
                checked={alignment === 'center'}
                onChange={() => setAlignment('center')}
              />
              По центру
            </label>
            <label>
              <input
                type="radio"
                value="left"
                checked={alignment === 'left'}
                onChange={() => setAlignment('left')}
              />
              По левому краю
            </label>
          </div>
        </div>


        <div className="wraper-plitka">
          <a href="/" className="home-button-logo">
            3D Конфигуратор комплексов
          </a>
          <div className="plitka-btn">
            <button><a href="/" className="home-button">Главная</a></button>
            <button onClick={goBack}>Назад</button>
          </div>
        </div>

        <div className="wraper-plitka">
          <div className="plitka-title">Расстояние между стелами:</div>
          <div className="plitka-controls">
            <button onClick={handleDecreaseSpacing}>-</button>
            <span>{(stelaSpacing * 100).toFixed(0)} см</span>
            <button onClick={handleIncreaseSpacing}>+</button>
          </div>
        </div>

        <div className="wraper-plitka">
        {/* Управление стелами */}
        {stelas.map((stela, index) => (
          <div key={index} className="wraper-plitka">
            
            <div>Стела {index + 1}:</div>
            <ThumbnailSelect
              selectedMonument={stela.monumentIndex}
              onSelect={(monumentIndex) => updateStela(index, { monumentIndex })}
            />
            <p></p>
            <div>Цвет стелы:</div>
            <div className="texture-selector">
              <ColorPikerTextureList
                materialSet={`rp_${stela.monumentIndex}`}
                onTextureClick={(rp_1MaterialIndex) => updateStela(index, { rp_1MaterialIndex })}
              />
            </div>

            <div key={index}>
              <button onClick={() => handleRotationChange(index, 'left')}>Ориентация влево</button>
              <button onClick={() => handleRotationChange(index, 'right')}>Ориентация вправо</button>
            </div>

            {/* Управление текстурой тумбы */}
            <div>Текстура тумбы:</div>
            <div className="texture-selector">
            <ColorPikerTextureList
              materialSet="GraniteTumba"
              onTextureClick={(materialIndex) => updatePedestalTexture(index, materialIndex)}
            />
            </div>

            {/* Кнопки для изменения размера тумбы */}
            <div>Размер тумбы:</div>
            <button onClick={() => handleDecreasePedestalSize(index)}>-</button>
            <span>{pedestalSizes[index].toFixed(2)}</span>
            <button onClick={() => handleIncreasePedestalSize(index)}>+</button>

            {/* Управление текстурой цветника */}
            <div>Текстура цветника:</div>
            <div className="texture-selector">
            <ColorPikerTextureList
              materialSet="LandZvetnik"
              onTextureClick={(materialIndex) => updateLandTexture(index, materialIndex)}
            />
            </div>

            {/* Управление текстурой земли в цветнике */}
            <div>Текстура земли в цветнике:</div>
            <div className="texture-selector">
            <ColorPikerTextureList
              materialSet="zvetnik"
              onTextureClick={(materialIndex) => updateZvetnikTexture(index, materialIndex)}
            />
            </div>

            {/* Кнопки для изменения размера земли в цветнике */}
            <div>Размер земли в цветнике:</div>
            <button onClick={() => handleDecreaseLandSize(index)}>-</button>
            <span>{landSizes[index].toFixed(2)}</span>
            <button onClick={() => handleIncreaseLandSize(index)}>+</button>

            <p></p>
            <div>Фамилия:</div>
            <input
              type="text"
              value={stela.surname}
              onChange={(e) => updateStela(index, { surname: e.target.value })}
              placeholder="Введите фамилию"
            />
            {/* Кнопки для изменения размера шрифта ФАМИЛИИ*/}
            <div>Размер шрифта:</div>
            <button onClick={() => handleDecreaseFontSize(index)}>-</button>
            <span>{stela.fontSize.toFixed(2)}</span>
            <button onClick={() => handleIncreaseFontSize(index)}>+</button>


          {/* Управление портретами */}
          
          
              
          {stela.portrets.map((portret, portretIndex) => (
              <div key={portretIndex}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, index, portretIndex)}
                />
                <button onClick={() => removePortret(index, portretIndex)}>Удалить портрет {portretIndex + 1}</button>
              </div>
            ))}
            <button onClick={() => addPortret(index)}>Добавить портрет</button>
           
         

          {/* Кнопки добавления и удаления имён */}
          {stela.firstNames.map((firstName, nameIndex) => (
            <div key={nameIndex}>
              <textarea
                value={firstName}
                onChange={(e) => {
                  const updatedNames = [...stela.firstNames];
                  updatedNames[nameIndex] = e.target.value;
                  setStelas((prevStelas) =>
                    prevStelas.map((stela, idx) =>
                      idx === index ? { ...stela, firstNames: updatedNames } : stela
                    )
                  );
                }}
                placeholder="Введите имя"
                rows={3}
              />
              <button onClick={() => removeFirstName(index, nameIndex)}>Удалить имя</button>
            </div>
          ))}

          <button onClick={() => addFirstName(index)}>Добавить имя</button>

          <div>
            <div>Размер шрифта для имен:</div>
            <button onClick={() => handleDecreaseFirstNameFontSize(index)}>-</button>
            <span>{stela.firstNameFontSize.toFixed(2)}</span>
            <button onClick={() => handleIncreaseFirstNameFontSize(index)}>+</button>
          </div>

          <div>
            <div>Line-height для имен:</div>
            <button onClick={() => handleDecreaseLineHeight(index)}>-</button>
            <span>{stela.lineHeight.toFixed(2)}</span>
            <button onClick={() => handleIncreaseLineHeight(index)}>+</button>
          </div>

          <div>Выравнивание имени:</div>
          <label>
            <input
              type="radio"
              value="center"
              checked={stela.textAlign === 'center'}
              onChange={() => updateStela(index, { textAlign: 'center' })}
            />
            Центр
          </label>
          <label>
            <input
              type="radio"
              value="left"
              checked={stela.textAlign === 'left'}
              onChange={() => updateStela(index, { textAlign: 'left' })}
            />
            Лево
          </label>
          <label>
            <input
              type="radio"
              value="right"
              checked={stela.textAlign === 'right'}
              onChange={() => updateStela(index, { textAlign: 'right' })}
            />
            Право
          </label>

          {/* Добавление и управление датами */}
          {stela.dates.map((date, dateIndex) => (
            <div key={dateIndex}>
              <textarea
                value={date}
                onChange={(e) => {
                  const updatedDates = [...stela.dates];
                  updatedDates[dateIndex] = e.target.value; // Обновляем только измененную дату
                  updateStela(index, { dates: updatedDates });
                }}
                placeholder="Введите дату"
                rows={3}
              />
              <button onClick={() => removeDate(index, dateIndex)}>Удалить дату</button>
            </div>
          ))}
          <button onClick={() => addDate(index)}>Добавить дату</button>
              
          {/* Размер шрифта для дат */}
          <div>
            <div>Размер шрифта для дат:</div>
            <button onClick={() => handleDecreaseDateFontSize(index)}>-</button>
            <span>{stela.dateFontSize.toFixed(2)}</span>
            <button onClick={() => handleIncreaseDateFontSize(index)}>+</button>
          </div>

          {/* Line-height для дат */}
          <div>
            <div>Line-height для дат:</div>
            <button onClick={() => handleDecreaseDateLineHeight(index)}>-</button>
            <span>{stela.dateLineHeight.toFixed(2)}</span>
            <button onClick={() => handleIncreaseDateLineHeight(index)}>+</button>
          </div>

          {/* Выравнивание дат */}
          <div>Выравнивание даты:</div>
          <label>
            <input
              type="radio"
              value="center"
              checked={stela.textAlignDate === 'center'}
              onChange={() => updateStela(index, { textAlignDate: 'center' })}
            />
            Центр
          </label>
          <label>
            <input
              type="radio"
              value="left"
              checked={stela.textAlignDate === 'left'}
              onChange={() => updateStela(index, { textAlignDate: 'left' })}
            />
            Лево
          </label>
          <label>
            <input
              type="radio"
              value="right"
              checked={stela.textAlignDate === 'right'}
              onChange={() => updateStela(index, { textAlignDate: 'right' })}
            />
            Право
          </label>
       
          <div>Цвет текста:</div>
          <div className="text-color">
            <label>
              <input
                type="radio"
                value="#000000"
                checked={stela.textColor === '#000000'}
                onChange={() => updateStela(index, { textColor: '#000000' })}
              />
              <span style={{ backgroundColor: '#000000', display: 'inline-block', width: '20px', height: '20px', border: '1px solid #808080', marginRight: '10px' }}></span>
            </label>
            <label>
              <input
                type="radio"
                value="#FFFFFF"
                checked={stela.textColor === '#FFFFFF'}
                onChange={() => updateStela(index, { textColor: '#FFFFFF' })}
              />
              <span style={{ backgroundColor: '#FFFFFF', display: 'inline-block', width: '20px', height: '20px', border: '1px solid #808080', marginRight: '10px' }}></span>
            </label>
            <label>
              <input
                type="radio"
                value="#b28c6f"
                checked={stela.textColor === '#b28c6f'}
                onChange={() => updateStela(index, { textColor: '#b28c6f' })}
              />
              <span style={{ backgroundColor: '#b28c6f', display: 'inline-block', width: '20px', height: '20px', border: '1px solid #808080', marginRight: '10px' }}></span>
            </label>
          </div>

            {stelas.length > 1 && <button onClick={() => removeStela(index)}>Удалить стелу</button>}
          </div>
        ))}

        {stelas.length < 4 && <button onClick={addStela}>Добавить стелу</button>}

        </div>

        <div className="">Пол</div>
        <select value={selectedOption} onChange={handleSelectChange}>
          <option value="Плитка">Плитка</option>
          <option value="Трава">Трава</option>
        </select>

        <p></p>

        {/* Интерфейс управления количеством плиток */}
        <div className="wraper-plitka">
          <div className="plitka-title">Количество плиток:</div>
          <div className="plitka-controls">
            <div>
              <label>Количество по X: </label>
              <button onClick={handleDecreaseCountX}>-</button>
              <span>{countX}</span>
              <button onClick={handleIncreaseCountX}>+</button>
            </div>
            <div>
              <label>Количество по Y: </label>
              <button onClick={handleDecreaseCountY}>-</button>
              <span>{countY}</span>
              <button onClick={handleIncreaseCountY}>+</button>
            </div>
            <div>
              <label>Высота столбов: </label>
              <button onClick={handleDecreaseCornerCubeHeight}>-</button>
              <span>{(cornerCubeHeight * 100).toFixed(0)} см</span>
              <button onClick={handleIncreaseCornerCubeHeight}>+</button>
            </div>
          </div>
        </div>

        <div className="wraper-plitka">
          <div className="">Цвет плитки:</div>
          <div className="texture-selector">
            <TextureList
              materialSet="GranitePlitka" // Передаете имя нужного набора материалов
              onTextureClick={(index) => setTileMaterialIndex(index)}
            />
          </div>
        </div>

        <div className="wraper-plitka">
          <div className="">Цвет крайних плиток:</div>
          <div className="texture-selector">
            <TextureList
              materialSet="GraniteEdge" // Передаете имя набора материалов для крайних плиток
              onTextureClick={(index) => setEdgeMaterialIndex(index)}
            />
          </div>
        </div>

        <div className="wraper-plitka">
          <div className="">Цвет цокольной плитки:</div>
          <div className="texture-selector">
            <TextureList
              materialSet="ZokolPlitka" // Передаете имя набора материалов для второго слоя плитки
              onTextureClick={(index) => setZokolMaterialIndex(index)}
            />
          </div>
        </div>

        <div className="wraper-plitka">
          <div className="">Цвет столбов:</div>
          <div className="texture-selector">
            <TextureList
              materialSet="GraniteStolb" // Передаете имя нужного набора материалов
              onTextureClick={(index) => setStolbMaterialIndex(index)}
            />
          </div>
        </div>

        <div className="wraper-plitka">
          <div className="">Цвет ограды:</div>
          <div className="texture-selector">
            <TextureList
              materialSet="GraniteOgrada" // Передаете имя набора материалов для ограды
              onTextureClick={(index) => setFenceMaterialIndex(index)}
            />
          </div>
        </div>

        <div className="wraper-plitka">
          <div>
            <label>Высота ограды: </label>
            <button onClick={handleDecreaseFenceHeight}>-</button>
            <span>{(fenceHeight * 100).toFixed(0)} см</span>
            <button onClick={handleIncreaseFenceHeight}>+</button>
          </div>
          <div>
            <label>Толщина ограды: </label>
            <button onClick={handleDecreaseFenceThickness}>-</button>
            <span>{(fenceThickness * 100).toFixed(0)} см</span>
            <button onClick={handleIncreaseFenceThickness}>+</button>
          </div>

          <div>
            <label>Позиция по высоте: </label>
            <button onClick={handleDecreaseFencePositionY}>-</button>
            <span>{(fencePositionY * 100).toFixed(0)} см</span>
            <button onClick={handleIncreaseFencePositionY}>+</button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default App;