// ColorPikerTextureList.jsx
import React from 'react';
import TextureThumbnail from './TextureThumbnail';
import materials from './materials';

const ColorPikerTextureList = ({ materialSet, onTextureClick }) => {
  const textures = materials[materialSet]; // Получаем массив текстур

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {textures.map((texture, index) => (
        <TextureThumbnail
          key={index}
          texture={texture.colorPiker} // Используем colorPiker из массива текстур
          onClick={() => onTextureClick(index)} // Передаем индекс текстуры
        />
      ))}
    </div>
  );
};

export default ColorPikerTextureList;
