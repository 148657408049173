// ThumbnailSelect.jsx
import React from 'react';
import materials from './materials'; // Импорт материалов

const ThumbnailSelect = ({ selectedMonument, onSelect }) => {
  // Массив изображений и названий
  const options = [
    {
      value: 1,
      image: materials.rp_1[0].thumbnaleMap,
      label: 'Резной Памятник 1',
    },
    {
      value: 2,
      image: materials.rp_2[0].thumbnaleMap,
      label: 'Резной Памятник 2',
    },
    // Добавьте сюда другие элементы
    {
      value: 1,
      image: materials.rp_1[0].thumbnaleMap,
      label: 'Резной Памятник 1',
    },
    {
      value: 2,
      image: materials.rp_2[0].thumbnaleMap,
      label: 'Резной Памятник 2',
    },
    // и так далее
  ];

  return (
    <div className="thumbnail-select">
      {options.map((option) => (
        <div
          key={option.value}
          className={`thumbnail-item ${selectedMonument === option.value ? 'selected' : ''}`}
          onClick={() => onSelect(option.value)}
        >
          <img
            src={option.image}
            alt={option.label}
            style={{ width: '100px', height: 'auto' }}
          />
          <div>{option.label}</div>
        </div>
      ))}
    </div>
  );
};

export default ThumbnailSelect;


