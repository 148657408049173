// materials.js
const materials = {

  GraniteStolb: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  GraniteOgrada: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  GraniteTumba: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  LandZvetnik: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  GranitePlitka: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  ZokolPlitka: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  GraniteEdge: [
    {
      baseColor: '/textures/Black_granite_BaseColor.jpg',
      roughness: '/textures/Black_granite_Roughness3.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/issetskii.png',
      roughness: '/textures/issetskii_Roughness3.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  zvetnik: [
    {
      baseColor: '/textures/grass2.jpg',
      roughness: '/textures/rougness.jpg',
      normalMap: '/textures/Black_granite_Normal3.jpg',
    },
    {
      baseColor: '/textures/earth.jpg',
      roughness: '/textures/rougness.jpg',
      normalMap: '/textures/issetskii_Normal3.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  rp_1: [
    {
      baseColor: '/textures/rp/rp_1/color_gabro_1.jpg',
      roughness: '/textures/rp/rp_1/roughness_gabro_1.jpg',
      normalMap: '/textures/rp/rp_1/normal_gabro_1.jpg',
      thumbnaleMap: '/textures/rp/rp_1/thumbnale.jpg',
      colorPiker: '/textures/rp/rp_1/color_gabro_1.jpg',
    },
    {
      baseColor: '/textures/rp/rp_1/color_green_1.jpg',
      roughness: '/textures/rp/rp_1/roughness_gabro_1.jpg',
      normalMap: '/textures/rp/rp_1/normal_gabro_1.jpg',
      thumbnaleMap: '/textures/rp/rp_1/thumbnale.jpg',
      colorPiker: '/textures/rp/rp_1/color_green_1.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],
  rp_2: [
    {
      baseColor: '/textures/rp/rp_2/color_gabro.jpg',
      roughness: '/textures/rp/rp_2/roughness.jpg',
      normalMap: '/textures/rp/rp_2/normal.jpg',
      thumbnaleMap: '/textures/rp/rp_2/thumbnale.jpg',
      colorPiker: '/textures/rp/rp_1/color_gabro_1.jpg',
    },
    {
      baseColor: '/textures/rp/rp_2/color_green.jpg',
      roughness: '/textures/rp/rp_2/roughness.jpg',
      normalMap: '/textures/rp/rp_2/normal.jpg',
      thumbnaleMap: '/textures/rp/rp_2/thumbnale.jpg',
      colorPiker: '/textures/rp/rp_1/color_green_1.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

  Polka: [
    {
      baseColor: '/textures/polka/polka_1/polka_volna_gabro.jpg',
      roughness: '/textures/polka/polka_1/polka_volna_roughness.png',
      normalMap: '/textures/polka/polka_1/polka_volna_normal.jpg',
      thumbnaleMap: '/textures/rp/rp_1/thumbnale.jpg',
      colorPiker: '/textures/rp/rp_1/color_gabro_1.jpg',
    },
    {
      baseColor: '/textures/polka/polka_1/polka_volna_zmeevik.png',
      roughness: '/textures/polka/polka_1/polka_volna_roughness.png',
      normalMap: '/textures/polka/polka_1/polka_volna_normal.jpg',
      thumbnaleMap: '/textures/rp/rp_1/thumbnale.jpg',
      colorPiker: '/textures/rp/rp_1/color_green_1.jpg',
    },
    // ... добавьте остальные материалы для тумбы
  ],

};

export default materials;